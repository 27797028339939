import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/seva/ScintillaOfArt2023/1.jpg';
// import p2 from 'assests/Photos/seva/ScintillaOfArt2023/2.jpg';
// import p3 from 'assests/Photos/seva/ScintillaOfArt2023/3.jpg';
// import p4 from 'assests/Photos/seva/ScintillaOfArt2023/4.jpg';
// import p5 from 'assests/Photos/seva/ScintillaOfArt2023/5.jpg';
// import p6 from 'assests/Photos/seva/ScintillaOfArt2023/6.jpg';
// import p7 from 'assests/Photos/seva/ScintillaOfArt2023/7.jpg';
// import p8 from 'assests/Photos/seva/ScintillaOfArt2023/8.jpg';
// import p9 from 'assests/Photos/seva/ScintillaOfArt2023/9.jpg';
// import p10 from 'assests/Photos/seva/ScintillaOfArt2023/10.jpg';
// import p11 from 'assests/Photos/seva/ScintillaOfArt2023/11.jpg';
// import p12 from 'assests/Photos/seva/ScintillaOfArt2023/12.jpg';
// import p13 from 'assests/Photos/seva/ScintillaOfArt2023/13.jpg';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from './components';

const ScintillaOfArt2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/9.webp`;
  const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/10.webp`;
  const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/11.webp`;
  const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/12.webp`;
  const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/sewa/ScintillaOfArt2023/13.webp`;
 

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 2,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
        },
        {
          src: p6,
          source: p6,
          rows: 1,
          cols: 1,
        },
       
        {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
        },
        {
              src: p10,
              source: p10,
              rows: 1,
              cols: 1,
            },
            {
              src: p11,
              source: p11,
              rows: 1,
              cols: 1,
            },
            {
              src: p12,
              source: p12,
              rows: 1,
              cols: 1,
            },
            {
                  src: p13,
                  source: p13,
                  rows: 1,
                  cols: 1,
                },
                {
                  src: p7,
                  source:p7,
                  rows: 2,
                  cols: 1,
                },
                {
                  src: p8,
                  source: p8,
                  rows: 2,
                  cols: 1,
                },
           
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      SCINTILLA OF ART 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 10	  &  Date: 11 OCTOBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Scintilla of Art event was held on 11 October 2023 was an absolute extravaganza! It was a platform where the incredibly talented students of class 10 showcased their artistic prowess to spread joy and make a difference in the lives of those in need, by donating the proceeds earned to a charitable cause. 
        <br></br>
        Walking through the exhibition hall on the day of the event, one could not help but be amazed by the sheer talent on display. The Pottery section showcased beautifully crafted clay pots, vases, and sculptures, each one unique in its own way. The Decor items section was a sight to behold, with intricately designed wall hangings, and handmade ornaments adorning the walls. The Painting section was a riot of colours, with vibrant canvases depicting various themes and emotions. The Cooking Without Fire section tantalized everyone's taste buds with delectable snacks and desserts prepared by the budding chefs. The Diya making section showcased stunning clay lamps, while the Doll Making section displayed adorable handmade dolls that captured the essence of creativity.
        <br></br>
        Throughout the event students supported and encouraged each other, creating an atmosphere of camaraderie, unity, and teamwork. Adding onto that, students learnt entrepreneurship skills by having a pragmatic understanding of how the market works and the challenges faced by people in the business sector, to keep their line of work alive. This event taught students that they must respect the work done by each person, irrespective of the background they come from. It helped instigate the value of Dignity of labour. 
        <br></br>
        In conclusion, the Scintilla of Art event was a resounding success. It provided a platform for the young artists to shine and express themselves through various art forms. The event was a testament to their hard work, dedication, and passion for art. It is safe to say that the future of art is in good hands with these talented individuals.
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Climate change is the Everest of all problems, the thorniest challenge facing humankind - Lewis Gordon Pugh”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023/>
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default ScintillaOfArt2023;

